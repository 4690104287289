<template>
    <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center"/>
          <strong> Eventos</strong>
          <div class="card-header-actions">
              <CButton color="primary" @click="primaryModal = true" class="mr-1">
                Agregar Evento
              </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          
          <CDataTable
            :items="items"
            :fields="fields"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
          >
            <template #acciones="{item}">
              <td>
                <CBadge>
                  {{item.id}}
                </CBadge>
              </td>
            </template>
          </CDataTable>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>

import $ from 'jquery'
import axios from 'axios'

/*const items = [
  { username: 'Samppa Nori', registered: '2012/01/01', role: 'Member', status: 'Active'},
  { username: 'Estavan Lykos', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
  { username: 'Chetan Mohamed', registered: '2012/02/01', role: 'Admin', status: 'Inactive'}
]*/
//const items2 ='';
//console.log(items);

const fields = [
  { key: 'name', label: 'Acciones', _style:'min-width:200px' },
  { key: 'description', label: 'Descripción' },
  { key: 'acciones', label: 'Acciones', _style: 'width:1%', sorter: false, filter: false }
]

export default {
    name: 'AdvancedTables',
    data () {
        return {
            items: [],
            fields,
            details: [],
            collapseDuration: 0
        }
    },
    methods: {
        listarMercados(){
            let vue=this;
            axios.get('https://api-app.apuestatotal.dev/v1/markets')
                .then( function(response){
                    vue.items=response.data['result'];
                  //console.log(vue.arraySelection);
                  });

        }
    },
    mounted(){
      this.listarMercados()
    }
};
//console.log('Primero');
//Le agrega un campo id al array
//console.log(items.map((item, num_fila) => { return {...item, num_fila}}));
//console.log('Segundo');
//console.log(items);
</script>

<style>
</style>